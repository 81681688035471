export default [
  'dracaena trifasciata',
  'sansevieria',
  'sansevieria trifasciata',
  'snake plant',
  "mother in law's tongue",
  "jinn's tongue",
  'bow string hemp',
  'snake tongue',
  'chamaedorea elegans',
  'parlor palm',
  'parlour palm',
  'neanthe bella palm',
  'tabletop palm',
  'bella palm',
  'calathea lancifolia',
  'rattlesnake plant',
  'goeppertia insignis',
  'zamioculcas zamiifolia',
  'zz plant',
  'zanzibar gem',
  'welcome plant',
  'cardboard plant',
  'ficus elastica',
  'rubber plant',
  'rubber tree',
  'india rubber plant',
  'philodendron',
  'kalanchoe marmorata',
  'penwiper plant',
  'chlorophytum comosum',
  'spider plant',
  'calathea rufibarba',
  'velvet calathea',
  'beaucarnea recurvata',
  'ponytail palm',
  'dracaena fragans',
  'dracaena elegance',
  'aloe humilis',
  'hedgehog aloe',
  'maranta leuconeura',
  'red prayer plant',
  'pachira aquatica',
  'money tree',
  'ficus lyrata',
  'fiddle leaf fig',
  'monstera deliciosa',
  'monstera',
];
